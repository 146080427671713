/* eslint-disable no-empty */
import { bus } from "@/main";
import Cookies from "js-cookie";
const BASE_URL =
  "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts";
const user = {
  state: {
    regPage: 1,
    regUser: undefined,
    inviteCheck: undefined,
    user: "",
    userId: null,
    userDetails: "",
    currency: "NGN",
    payCountry: { cur: "NGN", sym: "₦" },
    banks: "",
    transactions: "",
    invoice: "",
    customers: "",
    state: "",
    country: "",
    city: "",
    payments: "",
    dataPlans: "",
    transaction: "",
    airtimePrices: "",
    cableTv: "",
    teamMembers: "",
    webHook: "",
    outflow: "",
    inflow: "",
    paymentLink: "",
    paymentLinkTransaction: "",
    businessInfo: "",
    checkOutDetails: "",
    verifyTransaction: "",
    auditLog: "",
    auditDetail: "",
    initializeTransfer: "",
    timer: "",
    oldTransfer: "",
    legderTransactions: "",
    ledgerTransDetails: "",
    gateways: "",
    payaccount: "",
    payout: "",
    payoutDetail: "",
    disputes: "",
    bankAccounts: "",
    referrer: "",
    twoFactor: null,
    beneficiary: "",
    schedule: "",
    employee: "",
    scheduleHistory: "",
    customerdetails: "",
    currentCustomer: "",
    bulkpaymentBreakdown: "",
    cacreg: undefined,
    account_statement: "",
    businessBanks: "",
    cashiertransactions: "",
    branchUsers: "",
    branchSalesChart: "",
    branchTransactions: "",
    teamBranchSales: "",
    tvlist: "",
    cabletvplans: "",
    electricityProvider: "",
    branchStaff: "",
    branchDetails: "",
    payroll: "",
    payrollDetails: "",
    cashierBranchTrans: "",
    alertSettings: "",
    teamList: "",
    pendingTeamList: "",
    prodcategories: "",
    products: "",
    storeOrders: "",

    //
    successMsg: undefined,

    //pricing
    defaultSubPrice: undefined,
    premiumSubPrice: undefined,
    platinumSubPrice: undefined,

    //sub-account customer
    subAccountCusDetails: undefined,

    //cache
    caches: {
      cards: undefined,
      mywallet: undefined,
      accountno: undefined,
      usdaccountno: undefined,
      verstatus: undefined,
      branches: undefined,
      branchSales: undefined,
      branchSalesPoint: undefined,
      dailygraph: undefined,
      branchgraph: undefined,
      subaccounts: undefined,
      payoutOffer: undefined,
      loanhistory: undefined,
      translimit: undefined,
      myevents: undefined,
      selectedEvent: undefined,
      eventtickets: undefined,
      disputes: undefined,
      acctManagers: undefined,
      mficashiertrans: undefined,
      mficashierbal: 0,
      mfiusers: undefined,
      mfitrans: undefined,
      mfioverview: undefined,
      mficust: undefined,
      mfipendloans: undefined,
      mfiupdrequest: undefined,
      apitrans: undefined,
      virtualaccount: undefined,
      apicollections: undefined,
      subacctcollections: undefined,
      collectionStats: undefined,
      apieventlogs: undefined,
      webhookevents: undefined,
      mfiloans: undefined,
      paycountries: undefined,
      pendingTransfers: undefined,
      currencylimits: undefined,
      bankstrength: undefined,
      salespointoverview: undefined,
      salespointtrans: undefined,
      cryptowallet: undefined,
    },
  },
  getters: {
    getCache: (state) => state.caches,
    getSuccessMsg: (state) => state.successMsg,
    getRegPage: (state) => state.regPage,
    getRegUser: (state) => state.regUser,
    getInviteCheck: (state) => state.inviteCheck,
    getUser: (state) => state.user,
    getUserId: (state) => state.userId,
    getUserDetails: (state) => state.userDetails,
    getBankDetails: (state) => state.banks,
    getTransactions: (state) => state.transactions,
    getInvoice: (state) => state.invoice,
    getCustomers: (state) => state.customers,
    getState: (state) => state.state,
    getCountry: (state) => state.country,
    getCity: (state) => state.city,
    getPayments: (state) => state.payments,
    getDataPlans: (state) => state.dataPlans,
    getTransactionDetail: (state) => state.transaction,
    getAirTimePrices: (state) => state.airtimePrices,
    getCableTv: (state) => state.cableTv,
    getTeamMembers: (state) => state.teamMembers,
    getWebHook: (state) => state.webHook,
    getInflow: (state) => state.inflow,
    getOutflow: (state) => state.outflow,
    getPaymentLink: (state) => state.paymentLink,
    getPaymentLinkTransaction: (state) => state.paymentLinkTransaction,
    getBusinessInfo: (state) => state.businessInfo,
    getCheckOutDetails: (state) => state.checkOutDetails,
    getVerifiedTransaction: (state) => state.verifyTransaction,
    getAuditLog: (state) => state.auditLog,
    getAuditDetail: (state) => state.auditDetail,
    getIntializeTransfer: (state) => state.initializeTransfer,
    getTransferTime: (state) => state.timer,
    getOldTransfer: (state) => state.oldTransfer,
    getLedgerTransaction: (state) => state.legderTransactions,
    getLedgerTransDetails: (state) => state.ledgerTransDetails,
    getGateways: (state) => state.gateways,
    getPayAccounts: (state) => state.payaccount,
    getPayout: (state) => state.payout,
    getPayoutDetail: (state) => state.payoutDetail,
    getDisputes: (state) => state.disputes,
    getBankAccounts: (state) => state.bankAccounts,
    getReferrer: (state) => state.referrer,
    getTwoFactor: (state) => state.twoFactor,
    getBeneficiary: (state) => state.beneficiary,
    getCurrency: (state) => state.currency,
    getPayCountry: (state) => state.payCountry,
    getSchedule: (state) => state.schedule,
    getEmployee: (state) => state.employee,
    getScheduleHistory: (state) => state.scheduleHistory,
    getCustomerDetails: (state) => state.customerdetails,
    getCurrentCustomer: (state) => state.currentCustomer,
    getBulkPaymentBreakdown: (state) => state.bulkpaymentBreakdown,
    getCacReg: (state) => state.cacreg,
    getAccountStatement: (state) => state.account_statement,
    getBusinessBanks: (state) => state.businessBanks,
    getCashierTransactions: (state) => state.cashiertransactions,
    getBranchSalesChart: (state) => state.branchSalesChart,
    getBranchUsers: (state) => state.branchUsers,
    getBranchTransactions: (state) => state.branchTransactions,
    getTeamBranchSales: (state) => state.teamBranchSales,
    getTvList: (state) => state.tvlist,
    getElectricityProvider: (state) => state.electricityProvider,
    getBranchStaff: (state) => state.branchStaff,
    getBranchDetails: (state) => state.branchDetails,
    getPayroll: (state) => state.payroll,
    getPayrollDetails: (state) => state.payrollDetails,
    getCashierBranchTrans: (state) => state.cashierBranchTrans,
    getAlertSettings: (state) => state.alertSettings,
    getTeamList: (state) => state.teamList,
    getPendingTeamList: (state) => state.pendingTeamList,
    getProdCategoriies: (state) => state.prodcategories,
    getProducts: (state) => state.products,
    getStoreOrder: (state) => state.storeOrders,
    getDefaultPrices: (state) => state.defaultSubPrice,
    getPremiumPrices: (state) => state.premiumSubPrice,
    getPlatinumPrices: (state) => state.platinumSubPrice,
    getSubAcctDetails: (state) => state.subAccountCusDetails,
  },
  mutations: {
    //update reg page
    updateRegPage: (state, details) => (state.regPage = details),
    updateRegUser: (state, details) => (state.regUser = details),

    updateSuccessMsg: (state, details) => (state.successMsg = details),

    //invite check
    updateInviteCheck: (state, details) => (state.inviteCheck = details),

    //update user
    updateUser: (state, details) => (state.user = details),

    updateUserId: (state, details) => (state.userId = details),

    //update user business details
    updateUserDetails: (state, details) => (state.userDetails = details),

    //update bank details
    updateBankDetails: (state, details) => (state.banks = details),

    //update transactions
    updateTransactions: (state, data) => (state.transactions = data),

    //update invoice
    updateInvoice: (state, data) => (state.invoice = data),

    //update customers
    updateCustomers: (state, data) => (state.customers = data),

    //update state
    updateState: (state, data) => (state.state = data),

    //update country
    updateCountry: (state, data) => (state.country = data),

    //update city
    updateCity: (state, data) => (state.city = data),

    //update payment
    updatePayments: (state, data) => (state.payments = data),

    //update data plans
    updateDataPlans: (state, data) => (state.dataPlans = data),

    //update transaction detail
    updateTransactionDetail: (state, data) => (state.transaction = data),

    //update Airtime
    updateAirtimePrice: (state, data) => (state.airtimePrices = data),

    //update cable tv
    updateCableTV: (state, data) => (state.cableTv = data),

    //update team members
    updateTeamMembers: (state, data) => (state.teamMembers = data),

    //update web hook
    updateWebHook: (state, data) => (state.webHook = data),

    //update inflow
    updateInflow: (state, data) => (state.inflow = data),

    //update outflow
    updateOutflow: (state, data) => (state.outflow = data),

    //update payment link
    updatePaymentLink: (state, data) => (state.paymentLink = data),

    //update payment link transaction
    updatePaymentLinkTransaction: (state, data) =>
      (state.paymentLinkTransaction = data),

    //update business info
    updateBusinessInfo: (state, data) => (state.businessInfo = data),

    //update payment checkout details
    updateCheckOutDetails: (state, data) => (state.checkOutDetails = data),

    //update verified transaction
    updateVerifiedTransaction: (state, data) =>
      (state.verifyTransaction = data),

    //update audit log
    updateAuditLog: (state, data) => (state.auditLog = data),

    //update audit detail
    updateAuditLogDetail: (state, data) => (state.auditDetail = data),

    //update one app transfer account details
    updateInitializeTransfer: (state, data) =>
      (state.initializeTransfer = data),

    //update old transfer
    updateOldTransfer: (state, data) => (state.oldTransfer = data),

    //update transfer countdown
    updateCountDown: (state, data) => {
      if (parseInt(state.timer) === 0) return;
      state.timer -= data;
    },

    //update transfer timer
    updateTransferTimer: (state, time) => {
      state.timer = parseInt(time);
    },

    //update ledger transaction
    updateLedgerTransaction: (state, data) => (state.legderTransactions = data),

    //update ledger transaction details
    updateLedgeTransDetails: (state, data) => (state.ledgerTransDetails = data),

    //update gateway details
    updateGateway: (state, data) => (state.gateways = data),

    //update payout accounts
    updatePayoutAccount: (state, data) => (state.payaccount = data),

    //update payout
    updatePayout: (state, data) => (state.payout = data),

    //update payout transaction detail
    updatePayoutDetail: (state, data) => (state.payoutDetail = data),

    //update disputes
    updateDispute: (state, data) => (state.disputes = data),

    //update business account
    updateBankAccount: (state, data) => (state.bankAccounts = data),

    //update referrer
    updateReferrer: (state, data) => (state.referrer = data),

    //update two factor
    updateTwoFactor: (state, data) => (state.twoFactor = data),

    //update beneficiary list
    updateBeneficiaryList: (state, data) => (state.beneficiary = data),

    //update currency
    updateCurrency: (state, data) => (state.currency = data),

    //update pay country
    updatePayCountry: (state, data) => (state.payCountry = data),

    //update scheduled payments
    updateSchedule: (state, data) => (state.schedule = data),

    //update employee
    updateEmployee: (state, data) => (state.employee = data),

    //updat schedule history
    updateScheduleHistory: (state, data) => (state.scheduleHistory = data),

    //update customer details
    updateCustomerDetails: (state, data) => (state.customerdetails = data),

    //update current customer
    updateCurrentCustomer: (state, data) => (state.currentCustomer = data),

    //update bulkpayment breakdown details
    updatebulkpaymentBreakDown: (state, payload) =>
      (state.bulkpaymentBreakdown = payload),

    //update cac registrations
    updateCAC: (state, payload) => (state.cacreg = payload),

    //update account state
    updateAccountStatement: (state, payload) =>
      (state.account_statement = payload),

    //update business bank lists
    updateBusinessBanks: (state, data) => (state.businessBanks = data),

    //update cashier transactions
    updateCashierTransactions: (state, data) =>
      (state.cashiertransactions = data),

    updateBranchSalesChart: (state, data) => (state.branchSalesChart = data),

    updateBranchUsers: (state, data) => (state.branchUsers = data),

    updateBranchTransactions: (state, data) =>
      (state.branchTransactions = data),

    updateTeamBranchSales: (state, data) => (state.teamBranchSales = data),

    updateTvList: (state, data) => (state.tvlist = data),

    updateElectrictyProvider: (state, data) =>
      (state.electricityProvider = data),

    updateBranchStaff: (state, data) => (state.branchStaff = data),

    updateBranchDetails: (state, data) => (state.branchDetails = data),

    updatePayroll: (state, data) => (state.payroll = data),

    updatePayrollDetails: (state, data) => (state.payrollDetails = data),

    updateCashierBranchTrans: (state, data) =>
      (state.cashierBranchTrans = data),

    updateAlertSettings: (state, data) => (state.alertSettings = data),

    updateTeamList: (state, data) => (state.teamList = data),

    updatePendingTeamList: (state, data) => (state.pendingTeamList = data),

    updateProdCategories: (state, data) => (state.prodcategories = data),

    updateProducts: (state, data) => (state.products = data),

    updateStoreOrder: (state, data) => (state.storeOrders = data),

    //update prices
    updateDefaultPrices: (state, data) => (state.defaultSubPrice = data),
    updatePremiumPrices: (state, data) => (state.premiumSubPrice = data),
    updatePlatinumPrices: (state, data) => (state.platinumSubPrice = data),

    setSubAcctCusDetails: (state, data) => (state.subAccountCusDetails = data),

    //cache
    updateCache: (state, details) =>
      (state.caches[details.key] = details.value),
  },
  actions: {
    //log user in
    loginUser: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("pword", data.password);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/login2",
          {
            method: "POST", // or 'PUT'
            body: formData,
          }
        );
      } catch (e) {
        return {
          data: {
            msg: e.msg,
            status: false,
          },
        };
      }
    },

    //log user in using 2fa
    login2Fa: async (context, data) => {
      try {
        const fcmtoken = Cookies.get("fcm-token");
        let formData = new FormData();
        formData.append("userid", context.getters.getUser.userid);
        formData.append("secretcode", data.secretcode);
        formData.append("authtype", data.authtype);
        formData.append("apptoken", fcmtoken);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/authenticateme2",
          {
            method: "POST", // or 'PUT'
            body: formData,
          }
        );
      } catch (e) {
        return {
          data: {
            msg: e.msg,
            status: false,
          },
        };
      }
    },

    switch2Fa: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", data.businessid);
        formData.append("logintype", data.logintype);
        formData.append("secretcode", data.secretcode);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/authenticate-login",
          {
            method: "POST", // or 'PUT'
            body: formData,
          }
        );
      } catch (e) {
        return {
          data: {
            msg: e.msg,
            status: false,
          },
        };
      }
    },

    //fetch user details
    fetchUserCredentials: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/getinfo",
          {
            method: "POST", // or 'PUT'
            body: formData,
          }
        )
          .then((response) => response.json())
          .then(async (response) => {
            if (response.status === false) {
              window.sessionStorage.clear();
              window.localStorage.clear();
              bus.$emit("showDialog", {
                type: "error",
                title: "Session Expired!",
                message: "A new user session detected",
                action: "logout",
              });
              return;
            }
            if (response.status && response.account_category == "Not a fit") {
              window.sessionStorage.clear();
              window.localStorage.clear();
              bus.$emit("showDialog", {
                type: "error",
                title: "Access Denied!",
                message:
                  "Your business has been classified as not a fit for Boldd. If you think this is a mistake, kindly reach out to our support. Thank you!",
                action: "logout",
              });
              return;
            }
            return context.commit("updateUserDetails", response);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //register new users
    registerUser: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("fname", data.firstname);
        formData.append("sname", data.lastname);
        formData.append("busname", data.businessName);
        formData.append("busemail", data.businessEmail);
        formData.append("countryCode", data.countryCode);
        formData.append("phn", data.phone);
        formData.append("pwd", data.password);
        formData.append("desc", data.businessDesc);
        formData.append("whorefer", data.whorefer);
        formData.append("referby", data.referby);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/createaccount  ",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch user account details
    fetchAccountNos: async (context) => {
      await context.dispatch("httpRequest", {
        url: "accountno",
        caches: [
          { key: "accountno", value: "banklist" },
          { key: "usdaccountno", value: "usddata" },
        ],
      });
    },

    //fetch bank details
    fetchBankDetails: async (context) => {
      try {
        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/listbanks"
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateBankDetails", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify user bank details
    verifyBankDetails: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("acctno", data.acctno);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifybankacct ",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //submit bank verification
    submitVerification: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("acctno", data.acctno);
        formData.append("acctname", data.acctname);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("bvnn", data.bvnn);
        formData.append("busadr", data.busadr);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifyme ",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify user bvn
    verifyBvn: async (context, data) => {
      try {
        const formData = new FormData();

        formData.append("acctno", data.acctno);
        formData.append("bankcode", data.bankcode);
        formData.append("bbv", data.bvn);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/bvncheck",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch user transactions
    fetchUserTransactions: async (context, data) => {
      try {
        const formData = new FormData();

        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("currency", context.getters.getCurrency);
        formData.append("filterby", data.filterby ?? "");
        formData.append("custom", data.custom ?? "0");
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payments",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateTransactions", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {}
    },

    //fetch user invoice
    fetchUserInvoice: async (context) => {
      try {
        const formData = new FormData();

        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/invoices",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateInvoice", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {}
    },

    //fetch user customers
    fetchUserCustomers: async (context) => {
      try {
        const formData = new FormData();

        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/customers",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateCustomers", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {}
    },

    //fetch state
    fetchStates: async (context) => {
      try {
        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/statelists",
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateState", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {}
    },

    //fetch country
    fetchCountry: async (context) => {
      try {
        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/countries-lists",
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateCountry", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {}
    },

    //fetch town
    fetchCity: async (context, data) => {
      try {
        const form = new FormData();
        form.append("state", data.state);
        form.append("env", "web");
        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/citylists`,
          {
            method: "POST",
            body: form,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateCity", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {}
    },

    //submit user transfer request
    submitTransfer: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("acctno", data.acctno);
        formData.append("acctname", data.acctname);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("amount", data.amount);
        formData.append("desc", data.desc);
        formData.append("ctoken", data.ctoken);
        formData.append("trackingid", data.trackingid ?? "");
        formData.append("subaccount", data.subaccount ?? "");
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/sendmoney ",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //reset user password
    resetPassword: async (context, data) => {
      try {
        const form = new FormData();
        form.append("hisemail", data.email);
        form.append("env", "web");
        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/reset-password`,
          {
            method: "POST",
            body: form,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //update user password
    updatePassword: async (context, data) => {
      try {
        const form = new FormData();
        form.append("accountid", window.sessionStorage.accountid);
        form.append("otpcode", data.otp);
        form.append("newpass", data.password);
        form.append("confirmpass", data.confirmpass);
        form.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/recover-password`,
          {
            method: "POST",
            body: form,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //update user business profile
    updateUserBusiness: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("businessname", data.businessname);
        formData.append("business_adr", data.business_adr);
        formData.append("business_phone", data.business_phone);
        formData.append("business_email", data.business_email);
        formData.append("state", data.state);
        formData.append("city", data.city);
        formData.append("lga", data.lga);
        formData.append("tradename", data.tradename);
        formData.append("othertown", data.othertown);
        formData.append("business_desc", data.business_desc);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/business-settings`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch user payments
    fetchUserPayments: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payments`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updatePayments", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch data plan
    fetchDataPlans: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("provider", data.provider);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/getdataplans`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateDataPlans", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify email
    verifyEmail: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifyemail`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //upload document
    uploadDocument: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append(data.mode, data.file);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verify-docs`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //upload office building documents
    uploadOfficeDocuments: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("file", data.file);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/otherverdocs`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch transaction detail
    fetchTransactionDetail: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("reference", data.reference);
        formData.append("mode", data.mode);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/paydetails `,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateTransactionDetail", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //purchase data
    purchaseData: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("phoneno", data.phoneno);
        formData.append("datacode", data.datacode);
        formData.append("network", data.network);
        formData.append("ctoken", data.ctoken);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/databundle `,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch airtime prices
    fetchAirtimePrice: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("network", data.network);
        formData.append("amount", data.amount);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/getprices`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateAirtimePrice", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //purchase airtime
    purchaseAirtime: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("phoneno", data.phoneno);
        formData.append("ntwk", data.ntwk);
        formData.append("amount", data.amount);
        formData.append("ctoken", data.ctoken);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/airtime `,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch cable tv prices
    fetchCableTvPrice: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("tv", data.tv);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/gettvplan`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateCableTV", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    // create transaction pin
    createTransactionPin: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("pin", data.pin);
        formData.append("retrypin", data.retrypin);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/transpin`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    // create auth transaction pin
    createAuthTransPin: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("userid", context.getters.getUser.userid);
        formData.append("newpin", data.pin);
        formData.append("confirmpin", data.confirmpin);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/authpinsetup`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //update user transaction pin
    updateTransactionPin: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("oldpin", data.oldpin);
        formData.append("newpin", data.newpin);
        formData.append("confirmpin", data.confirmpin);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/updatepin`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //upload user image
    uploadProfile: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("image", data.image);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/uploadpic`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //change user password
    changePassword: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("currentpass", data.currentpass);
        formData.append("newpass", data.newpass);
        formData.append("retypepass", data.retypepass);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/change-pass`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch team members
    fetchTeamMembers: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/team-members `,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateTeamMembers", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //send Team Invite
    sendTeamInvite: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("useremail", data.useremail);
        formData.append("is_dev", data.is_dev);
        formData.append("role", data.role);
        formData.append("branchid", data.branchid);
        formData.append("expired", data.expired);
        formData.append("name", data.name);
        formData.append("phoneno", data.phoneno);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/inviteteam`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch web hook
    fetchWebHook: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/devtools`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateWebHook", response);
              return response;
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //save webhook
    saveWebHook: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("hookurl", data.hookurl);
        formData.append("iplisted", data.iplisted);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/savehookurl`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //generate webhook api
    generateWebHook: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/generatekeys`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch inflow or outflow
    fetchFlow: async (context, mode) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("type", mode);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/recent-trans`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              if (mode === "credit")
                return context.commit("updateInflow", response);
              if (mode === "debit")
                return context.commit("updateOutflow", response);
              return;
            }

            if (mode === "credit") return context.commit("updateInflow", "");
            if (mode === "debit") return context.commit("updateOutflow", "");
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch custom cities
    fetchCustomCity: async (context, data) => {
      try {
        const form = new FormData();
        form.append("state", data.state);
        form.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/citylists`,
          {
            method: "POST",
            body: form,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //submit cac payment
    submitCacPayment: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("regmode", data.regmode);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payforcac`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //submit cac information
    submitCAC: async (context, data) => {
      try {
        const form = new FormData();
        form.append("sesscode", context.getters.getUser.sesscode);
        form.append("userid", context.getters.getUser.userid);
        form.append("businessid", context.getters.getUser.business_id);
        form.append("regmode", data.regmode);
        form.append("proposed_name1", data.proposed_name1);
        form.append("proposed_name2", data.proposed_name2);
        form.append("proposed_name3", data.proposed_name3);
        form.append("bizcommence_date", data.bizcommence_date);
        form.append("business_nature", data.business_nature);
        form.append("business_adr", data.business_adr);
        form.append("business_state", data.business_state);
        form.append("business_city", data.business_city);
        form.append("business_adr2", data.business_adr2);
        form.append("business_state2", data.business_state2);
        form.append("business_city2", data.business_city2);
        form.append("owner_sname", data.owner_sname);
        form.append("owner_fname", data.owner_fname);
        form.append("owner_oname", data.owner_oname);
        form.append("owner_nationality", data.owner_nationality);
        form.append("former_nationality", data.former_nationality);
        form.append("owner_gender", data.owner_gender);
        form.append("owner_dob", data.owner_dob);
        form.append("owner_phone", data.owner_phone);
        form.append("owner_email", data.owner_email);
        form.append("idtype", data.idtype);
        form.append("idcardno", data.idcardno);
        form.append("occupation", data.occupation);
        form.append("owner_adr", data.owner_adr);
        form.append("owner_state", data.owner_state);
        form.append("owner_city", data.owner_city);
        form.append("paymentref", data.paymentref);
        form.append("idcard", data.idcard);
        form.append("owner_passport", data.owner_passport);
        form.append("owner_signature", data.owner_signature);
        form.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/regcac`,
          {
            method: "POST",
            body: form,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //payment link
    fetchPaymentLink: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/paylinks`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updatePaymentLink", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //create payment link
    createPaymentLink: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("pagename", data.pagename);
        formData.append("currency", data.currency);
        formData.append("url", data.url);
        formData.append("amnt", data.amnt);
        formData.append("desc", data.desc);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/single-paylink`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //disable payment link
    togglePaymentLink: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("pageid", data.pageid);
        formData.append("mode", data.mode);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/update-singlepay`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch payment link transaction
    fetchPaymentLinkTransaction: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("pageid", data.pageid);
        formData.append("pagetype", data.pagetype);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/pagetrans`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            return context.commit("updatePaymentLinkTransaction", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch business info
    fetchBusinessInfo: async (context, paylink) => {
      try {
        let formData = new FormData();
        formData.append("plink", paylink);
        formData.append("env", "web");
        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/thebusinfo`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            return context.commit("updateBusinessInfo", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //initiate payment
    initiatePayment: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("amount", data.amount);
        formData.append("fname", data.fname);
        formData.append("lname", data.lname);
        formData.append("phn", data.phn);
        formData.append("currency", data.currency);
        formData.append("email", data.email);
        formData.append("pagetype", data.pagetype);
        formData.append("businessid", data.bisid);
        formData.append("pageid", data.pageid);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/initiatepayment",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch check out details
    fetchCheckOutDetails: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("token", data.token);
        formData.append("reference", data.reference);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/loadthecheckout",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            return context.commit("updateCheckOutDetails", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //process transactions
    processTransaction: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("token", data.token);
        formData.append("reference", data.reference);
        formData.append("gateway", data.gateway);
        formData.append("redirectlink", data.redirectlink);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/process-payment",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify transactions
    verifyTransaction: async (context, data) => {
      try {
        let formData = new FormData();

        formData.append("reference", data.reference);
        formData.append("confirm", data.confirm);
        formData.append("gateway", data.gateway);
        formData.append("gatewayref", data.gatewayref);
        formData.append("gatewaystatus", data.gatewaystatus);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verify-transaction",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            return context.commit("updateVerifiedTransaction", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify user account
    verifyUserLogin: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("ref", data.ref);
        formData.append("token", data.token);
        formData.append("email", data.email);
        formData.append("pwd", data.pwd);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifypaymentlogin",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //confirm login otp
    verifyOTP: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("ref", data.ref);
        formData.append("token", data.token);
        formData.append("otp", data.otp);
        formData.append("appuserid", data.appuserid);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/chckloginotp",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch audit log
    fetchAuditLog: async (context) => {
      try {
        let formData = new FormData();

        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/auditlog",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            return context.commit("updateAuditLog", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch audit log detail
    fetchAuditLogDetail: async (context, data) => {
      try {
        let formData = new FormData();

        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("audit_id", data.audit_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/auditdetails",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            return context.commit("updateAuditLogDetail", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //initialize transfer
    intializeTransfer: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("reference", data.reference);
        formData.append("token", data.token);
        formData.append("gateway", data.gateway);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/oneappmethod",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateInitializeTransfer", response);
            if (!context.getters.getTransferTime)
              return context.commit("updateTransferTimer", response.countdown);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //transfer time out countdown
    transferTimeCountDown: async (context) => {
      setInterval(async () => {
        if (context.getters.getTransferTime <= 0) {
          clearInterval();

          return;
        }
        context.commit("updateCountDown", 1);
      }, 1005);
    },

    //check dynamic transfer
    checkDynamicTransfer: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("reference", data.reference);
        formData.append("token", data.token);
        formData.append("accntno", data.accntno);
        formData.append("amount", data.amount);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/checkdynamic-transfer",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //toggle user environment
    changeEnvironment: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("mode", data.mode);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/switch-env",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch all ledger transactions
    fetchLedgerTransaction: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("currency", context.getters.getCurrency);
        formData.append("filterby", data.filterby ?? "");
        formData.append("custom", data.custom ?? "");
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/ledger-transactions",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateLedgerTransaction", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch ledger transaction detail
    fetchLedgerTransDetails: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("reference", data.reference);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/ledger-transdetails",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            context.commit("updateLedgeTransDetails", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch gateways
    fetchGateways: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/checkgateways",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateGateway", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //update user payment gateway settings
    submitGatewaySettings: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("paywith_oneapp", data.paywith_oneapp);
        formData.append("paywith_rave", data.paywith_rave);
        formData.append("paywith_pstk", data.paywith_pstk);
        formData.append("paywith_inter", data.paywith_inter);
        formData.append("paywith_monnify", data.paywith_monnify);
        formData.append("paywith_squad", data.paywith_squad);
        formData.append("paywith_stripe", data.paywith_stripe);
        formData.append("paywith_paypal", data.paywith_paypal);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/gateway-settings",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //update user transaction fee charges
    updateTransactionChargee: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("whopay", data.whopay);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/transfee-settings",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //toggle user 2fa
    toggle2fa: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("login2fa", data.login2fa);
        formData.append("transfer2fa", data.transfer2fa);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/twofactor",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify user login details
    verifyLoginDetails: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("emm", data.emm);
        formData.append("pww", data.pww);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/validate-accountcheck",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //generate user upgrade otp
    generateOTP: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("useremail", data.useremail);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/upgradeotp",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify user upgrade otp
    verifyUpgradeOTP: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("useremail", data.useremail);
        formData.append("otpcode", data.otpcode);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verify-upgradeotp",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //submit user upgrade details
    submitUpgradeDetails: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("emm", data.emm);
        formData.append("pww", data.pww);
        formData.append("busemail", data.busemail);
        formData.append("busname", data.busname);
        formData.append("biz_phone", data.biz_phone);
        formData.append("desc", data.desc);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/upgradetobiz",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch user payout accounts
    fetchPayoutAccounts: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payout-accounts",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response) {
              context.commit("updatePayoutAccount", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //set user payout account
    setPayoutAccount: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("payoutacnt_id", data.payoutacnt_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/change-payoutacct",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //delete user pay out account
    deletePayoutAccount: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("payoutacnt_id", data.payoutacnt_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/deletepayout",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //add a new payout account
    addNewPayAccount: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("acctno", data.acctno);
        formData.append("acctname", data.acctname);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("currency", data.currency);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/add-payoutaccount",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch pay out
    fetchPayout: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payouts",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response) {
              context.commit("updatePayout", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch payout detail
    fetchPayoutDetail: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("payoutref", data.payoutref);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payoutdetails",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response) {
              context.commit("updatePayoutDetail", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch user disputes
    fetchDispute: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/disputes",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response) {
              context.commit("updateDispute", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //decline user dispute
    declineDispute: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("disputeid", data.disputeid);
        formData.append("txref", data.txref);
        formData.append("name", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("claim", data.claim);
        formData.append("descres", data.descres);
        formData.append("receipt", data.receipt);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/decline-disputes",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //accept user dispute
    acceptDispute: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("disputeid", data.disputeid);
        formData.append("transref", data.transref);
        formData.append("customername", data.customername);
        formData.append("customeremail", data.customeremail);
        formData.append("customerphone", data.customerphone);
        formData.append("dclaim", data.dclaim);
        formData.append("torefund", data.torefund);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/accept-disputes",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify bvn new
    verifyBvnNew: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("busadr", data.busadr);
        formData.append("bvnn", data.bvnn);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifyme2",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //complete bvn registration
    validateBVN: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append(
          "businessid",
          sessionStorage.new_business_id || context.getters.getUser.business_id
        );
        formData.append("busadr", data.busadr);
        formData.append("bvnn", data.bvnn);
        formData.append("otp", data.otp);
        formData.append("env", "web");
        formData.append("desc", data.desc);
        formData.append("lga", data.lga);
        formData.append("state", data.state);
        formData.append("city", data.city);

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/validatebvotp",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //update team member
    UpdateTeamMemberStatus: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("updstatus", data.updstatus);
        formData.append("teamid", data.teamid);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/updateteam",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch bank account
    fetchBankAccounts: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mybusiness",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateBankAccount", response);
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //submit new business
    addBusiness: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("busemail", data.busemail);
        formData.append("busname", data.busname);
        formData.append("busphone", data.busphone);
        formData.append("desc", data.desc);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/addnewbiz",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify new business
    verifyNewBusiness: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("newbizid", data.newbizid);
        formData.append("bvnn", data.bvnn);
        formData.append("city", data.city);
        formData.append("state", data.state);
        formData.append("busadr", data.busadr);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifynewbiz",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //add new invoice
    newInvoice: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("customer_email", data.customer_email);
        formData.append("unitprice", data.unitprice);
        formData.append("quantity", data.quantity);
        formData.append("duedate", data.duedate);
        formData.append("currency", data.currency);
        formData.append("item", data.item);
        formData.append("desc", data.desc);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/create-invoice",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //reset user pin
    resetPin: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/reset-pin",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //confirm reset pin
    confirmResetPin: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("pinotp", data.pinotp);
        formData.append("newpin", data.newpin);
        formData.append("confirmpin", data.confirmpin);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/confirm-pinreset",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //generate payment link excel
    generatePaymentExcel: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("pageid", data.pageid);
        formData.append("pagetype", data.pagetype);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/pagetrans-excel",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch referrers
    fetchReferrers: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/referrals",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateReferrer", response.data);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch two factor details
    /*    fetchTwoFactor: async (context) => {
      try {
        let formData = new FormData();
        formData.append("userid", context.getters.getUserId);
        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mfasetup",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateTwoFactor", response);
          });
      } catch (e) {
        console.log(e.message);
      }
    }, */

    fetchTwoFactor: async (context) => {
      try {
        let formData = new FormData();
        formData.append("userid", context.getters.getUser.userid);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mfasetup",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateTwoFactor", response);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //complete 2fa setup
    completeTwoFactor: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("userid", context.getters.getUser.userid);
        formData.append("secretcode", data.secretcode);
        formData.append("hiscode", data.code);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/validate2fa",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //remove 2fa setup
    DeleteTwoFactor: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/remove2fa",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch beneficiary list
    fetchBeneficiaryList: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/beneficiaries",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateBeneficiaryList", response);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //add new beneficiary
    addBeneficiary: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("acctno", data.acctno);
        formData.append("beneficiary_name", data.acctname);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("currency", data.currency);
        formData.append("mail", data.email ?? "");
        formData.append("routing_number", data.routingNo ?? "");
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/add-beneficiary",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //remove beneficiary
    removeBeneficiary: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("beneficiaryid", data.beneficiaryid);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/remove-beneficiary",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //make bulk payment
    bulkPayment: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("paytype", data.paytype);
        formData.append("data", JSON.stringify(data.data));
        formData.append("paymentname", data.paymentname);
        formData.append("ctoken", data.ctoken);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/bulkpay",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch wallet
    fetchWallet: async (context) => {
      await context.dispatch("httpRequest", {
        url: "mywallet",
        caches: [{ key: "mywallet", value: "mywallet" }],
      });
    },

    //schedule payment
    schedulePayment: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("producttype", data.producttype);
        formData.append("schedulemode", data.schedulemode);
        formData.append("startdate", data.startdate);
        formData.append("enddate", data.enddate);
        formData.append("start_time", data.start_time);
        formData.append("frequency", data.frequency);
        formData.append("amount", data.amount);
        formData.append("desc", data.desc);
        formData.append("acctno", data.acctno);
        formData.append("acctname", data.acctname);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("currency", data.currency);
        formData.append("ctoken", data.ctoken);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/scheduler",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch scheduled payments
    fetchScheduledPayments: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/schedulelist",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateSchedule", response.data);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch employees
    fetchEmployees: async (context) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/employeelist",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateEmployee", response.data);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //add new employee
    addNewEmployee: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("acctno", data.acctno);
        formData.append("employee_name", data.employee_name);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("pension_amount", data.pension_amount);
        formData.append("pension_manager", data.pension_manager);
        formData.append("salary_amount", data.salary_amount);
        formData.append("rsano", data.rsano);
        formData.append("staffno", data.staffno);
        formData.append("tax_amount", data.tax_amount);
        formData.append("tin", data.tin);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/addemployee",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //edit employee
    editEmployee: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("empid", data.empid);
        formData.append("acctno", data.acctno);
        formData.append("employee_name", data.employee_name);
        formData.append("bankcode", data.bankcode);
        formData.append("bankname", data.bankname);
        formData.append("pension_amount", data.pension_amount);
        formData.append("pension_manager", data.pension_manager);
        formData.append("salary_amount", data.salary_amount);
        formData.append("rsano", data.rsano);
        formData.append("staffno", data.staffno);
        formData.append("tax_amount", data.tax_amount);
        formData.append("tin", data.tin);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/editemployee",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //add new schedule history
    fetchScheduleHistory: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("reference", data.reference);
        formData.append("transid", data.transid);
        formData.append("env", "web");
        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/schedulehistory",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateScheduleHistory", response);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //import employees
    importEmployees: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("file", data.file);
        formData.append("env", "web");

        return await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/importemployee",
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //change  scheduled payments
    ChangeScheduledPayments: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("dataid", JSON.stringify(data.data));
        formData.append("env", "web");
        console.log(data);

        let endpoint = "";
        if (data.mode === "enable") endpoint = "enableschedule";
        if (data.mode === "disable") endpoint = "disableschedule";
        if (data.mode === "remove") endpoint = "remove-schedule";

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/${endpoint}`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch customer details
    FetchCustomerDetails: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("customerid", data.id);
        formData.append("env", "web");

        await fetch(
          "https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/customertrans",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateCustomerDetails", response);
          });
      } catch (e) {
        console.log(e.message);
      }
    },

    //add new customer
    newCustomer: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("fname", data.fname);
        formData.append("lname", data.lname);
        formData.append("email", data.email);
        formData.append("phn", data.phn);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/addcustomer`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //requery ledger transaction
    queryLedgerTrans: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("reference", data.reference);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/transrequery`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    // toggle blacklisting for a  customer
    blackListCustomer: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("customer_email", data.customer_email);
        formData.append("env", "web");

        let endpoint =
          data.mode === "blacklist" ? "blacklistcust" : "unblacklistcust";

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/${endpoint}`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //upload bulk payment excel file
    uploadPaymentDetails: async (context, data) => {
      try {
        let formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("file", data.file);
        formData.append("productype", data.productype);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/importbulkpay`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch data  for bulk payment plan
    fetchBulkDataPlans: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("provider", data.provider);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/getdataplans`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch cac registrations
    fetchUserCAC: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mycacreg`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateCAC", response.data);
            return context.commit("updateCAC", []);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    getCacDetails: async (context, regid) => {
      return await context
        .dispatch("httpRequest", {
          url: "getcacreg",
          data: { regid: regid },
        })
        .then((response) => response);
    },

    payCAC: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("regmode", data.regmode);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payforcac`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    regCACBusinessInfo: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("regid", data.regid);
        formData.append("reference", data.reference);
        formData.append("proposed_name1", data.proposed_name1);
        formData.append("proposed_name2", data.proposed_name2);
        formData.append("proposed_name3", data.proposed_name3);
        formData.append("business_nature", data.business_nature);
        formData.append("bizcommence_date", data.bizcommence_date);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/cacbizreg`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    regCACBusinessAddress: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("regid", data.regid);
        formData.append("business_adr", data.business_adr);
        formData.append("business_state", data.business_state);
        formData.append("business_city", data.business_city);
        formData.append("business_adr2", data.business_adr2);
        formData.append("business_state2", data.business_state2);
        formData.append("business_city2", data.business_city2);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/cacreg-address`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    regCACProprietorInfo: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("regid", data.regid);
        formData.append("owner_passport", data.owner_passport);
        formData.append("owner_sname", data.owner_sname);
        formData.append("owner_fname", data.owner_fname);
        formData.append("owner_oname", data.owner_oname);
        formData.append("owner_nationality", data.owner_nationality);
        formData.append("former_nationality", data.former_nationality);
        formData.append("owner_gender", data.owner_gender);
        formData.append("owner_dob", data.owner_dob);
        formData.append("idcardno", data.idcardno);
        formData.append("owner_phone", data.owner_phone);
        formData.append("owner_email", data.owner_email);
        formData.append("idtype", data.idtype);
        formData.append("idcard", data.idcard);
        formData.append("occupation", data.occupation);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/cacreg-proprietors`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    regCACProprietorAddress: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("regid", data.regid);
        formData.append("owner_adr", data.owner_adr);
        formData.append("owner_state", data.owner_state);
        formData.append("owner_city", data.owner_city);
        formData.append("owner_signature", data.owner_signature);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/cacproprietor-addr`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    accountStatement: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("datefrom", data.date_from);
        formData.append("dateto", data.endto);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/statement-download`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    generateAccount: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("bkcode", data.bankcode);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/generate-account`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch branches
    fetchBranches: async (context) => {
      await context.dispatch("httpRequest", {
        url: "mybranches",
        caches: [{ key: "branches", value: "data" }],
      });
    },

    fetchBusinessBanks: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/listbizbank`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateBusinessBanks", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    addNewBranch: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchname", data.name);
        formData.append("state", data.state);
        formData.append("branchphone", data.phone);
        formData.append("bkcode", data.bkcode);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/createbranch`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchCashierTransactions: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/cashierpayn`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateCashierTransactions", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    confirmPayment: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("transref", data.reference);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/confirmpay`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    mapUser: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("teamid", data.teamid);
        formData.append("terminalid", data.terminalid);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/addbranchuser`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    generateBranchAccount: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("bkcode", data.bkcode);
        formData.append("terminalid", data.terminalid);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/addbranch-acctno`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch branch sales
    fetchBranchSales: async (context, data) => {
      await context.dispatch("httpRequest", {
        url: "branchsales",
        data: { filterby: data.filterby, custom: data.custom ?? "" },
        caches: [{ key: "branchSales", value: "data" }],
      });
    },

    fetchBranchSalesChart: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("year", data.year);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/saleschart`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            return context.commit("updateBranchSalesChart", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchBranchUsers: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/branchusers`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateBranchUsers", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchBranchTransactions: async (context, data) => {
      try {
        context.commit("updateBranchTransactions", "");
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("filterby", data.filterby);
        formData.append("custom", data.custom ?? "");
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mybranchtran`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateBranchTransactions", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchTeamBranchTransactions: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("filterby", data.filterby);
        formData.append("custom", data.custom ?? "");
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mybranchsales`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateTeamBranchSales", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchTvList: async (context) => {
      try {
        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/tvtype`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateTvList", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    verifyIUC: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("tv", data.tv);
        formData.append("iuc", data.iuc);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifyiuc`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    vendCable: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("tv", data.tv);
        formData.append("iuc", data.iuc);
        formData.append("custname", data.custname);
        formData.append("custno", data.custno);
        formData.append("amount", data.amount);
        formData.append("pln", data.pln);
        formData.append("packageid", data.packageid);
        formData.append("ctoken", data.ctoken);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/buycable`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    vendElectricity: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("custname", data.custname);
        formData.append("vendtype", data.vendtype);
        formData.append("amount", data.amount);
        formData.append("provider", data.provider);
        formData.append("meterno", data.meterno);
        formData.append("maxvend", data.maxvend);
        formData.append("minvend", data.minvend);
        formData.append("pckgid", data.pckgid);
        formData.append("ctoken", data.ctoken);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/vend-elect`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    verifyMeter: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("provider", data.provider);
        formData.append("meterno", data.meterno);
        formData.append("vendtype", data.vendtype);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/verifyelect`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchElectricityProvider: async (context) => {
      try {
        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/electtype`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateElectrictyProvider", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    submitSuggestion: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("subject", data.subject);
        formData.append("message", data.suggestion);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/survey`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchBranchStaff: async (context, data) => {
      try {
        context.commit("updateBranchStaff", "");
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mybranchteam`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateBranchStaff", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchBranchDetails: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("filterby", data.filterby);
        formData.append("custom", data.custom ?? "");
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/mybranch-overview`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateBranchDetails", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchPayroll: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/payroll`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updatePayroll", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    updateTeamRole: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("newrole", data.newrole);
        formData.append("teamid", data.teamid);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/managerole`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchPayrollDetails: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("ref", data.ref);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/viewpayroll`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updatePayrollDetails", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    toggleBranchTeam: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("statuscode", data.status);
        formData.append("teamid", data.teamid);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/holdteam`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    toggleAlert: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("sms", data.sms);
        formData.append("mail", data.mail);
        formData.append("subsms", data.subsms);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/transalert-setting`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchCashBranchTrans: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("branchid", data.branchid);
        formData.append("terminalid", data.terminalid);
        formData.append("filterby", data.filterby);
        formData.append("custom", data.custom);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/transbycashier`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateCashierBranchTrans", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchAlertSettings: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/alertstatus`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateAlertSettings", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    submitPayroll: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("narration", data.narration);
        // formData.append("schedulemode", data.schedulemode);
        // formData.append("startdate", data.startdate);
        formData.append("data", data.data);
        formData.append("env", "web");
        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/addpayroll`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    updatePayroll: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("narration", data.narration);
        formData.append("reference", data.reference);
        formData.append("data", data.data);
        formData.append("env", "web");
        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/updpayrol`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    approvePayroll: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("schedulemode", data.schedulemode);
        formData.append("reference", data.reference);
        formData.append("startdate", data.startdate);
        formData.append("ctoken", data.ctoken);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/approvepayroll`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },
    moveTeam: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("newbranchid", data.newbranchid);
        formData.append("teamid", data.teamid);
        formData.append("terminalid", data.terminalid);
        formData.append("env", "web");
        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/moveteam`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    exportPayroll: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("reference", data.reference);
        formData.append("env", "web");
        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/exportpayroll`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchTeamList: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/teamlist`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateTeamList", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchPendingTeamList: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/pending-invites`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updatePendingTeamList", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    resendOTP: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("type", data.type);
        formData.append("bvn", data.bvn);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/resendotp`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchProdCategories: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/store/storecategory`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateProdCategories", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    addProduct: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("images", data.images);
        formData.append("productname", data.productname);
        formData.append("price", data.price);
        formData.append("categoryid", data.categoryid);
        formData.append("desc", data.desc);
        formData.append("quantity", data.quantity);
        formData.append("currency", data.currency);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/store/addstoreproduct`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    storeRequest: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("checkedterm", data.checkedterm);
        formData.append("requestbody", data.requestbody);
        formData.append("env", "web");

        return await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/store/requestmall`,
          {
            method: "POST",
            body: formData,
          }
        );
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchProducts: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts/store/myproducts`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateProducts", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    fetchStoreOrder: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");

        await fetch(
          `https://api.oneappgo.com/bd702088a7598f6846c7ca3dfe082ec3/tts//store/storeorders`,
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status)
              return context.commit("updateStoreOrder", response);
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //fetch prices
    fetchSubPrices: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");
        await fetch(`${BASE_URL}/subcompare`, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              context.commit("updateDefaultPrices", response.defaultfee);
              context.commit("updatePremiumPrices", response.premium_fee);
              context.commit("updatePlatinumPrices", response.platinum_fee);
              return;
            }
          })
          .catch((e) => console.log(e.message));
      } catch (e) {
        console.log(e.message);
      }
    },

    //
    premiumSubscribe: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("plan", data.plan);
        formData.append("env", "web");
        formData.append("type", data.type);
        formData.append("renewal", data.renewal);

        return await fetch(`${BASE_URL}/upgradepckg`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    cancelSubscription: async (context) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");
        return await fetch(`${BASE_URL}/cancelsub`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    /**
     * =============================
     * NEW ONBOARDING API
     * =============================
     */

    //log user in
    login: async (context, data) => {
      try {
        const fcmtoken = Cookies.get("fcm-token");
        let formData = new FormData();
        formData.append("email", data.email);
        formData.append("pword", data.password);
        formData.append("apptoken", fcmtoken);
        formData.append("env", "web");

        return await fetch(`${BASE_URL}/signin`, {
          method: "POST", // or 'PUT'
          body: formData,
        });
      } catch (e) {
        return {
          data: {
            msg: e.msg,
            status: false,
          },
        };
      }
    },

    //register new users
    onboardNewUser: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("busname", data.busname);
        formData.append("staffvol", data.staffvol);
        formData.append("monthlysales", data.monthlysales);
        formData.append("fname", data.fname);
        formData.append("sname", data.sname);
        formData.append("phn", data.phone);
        formData.append("country", data.country);
        formData.append("localcurr", data.localcurr);
        formData.append("dialcode", data.dialcode);
        formData.append("email", data.email);
        formData.append("pwd", data.password);
        formData.append("whorefer", data.whorefer);
        formData.append("env", "web");
        formData.append("referby", data.refcode);
        return await fetch(`${BASE_URL}/regstart`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    onboardExistingUser: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("busname", data.busname);
        formData.append("staffvol", data.staffvol);
        formData.append("monthlysales", data.monthlysales);
        formData.append("email", data.email);
        formData.append("pwd", data.password);
        formData.append("env", "web");
        formData.append("referby", data.refcode);
        return await fetch(`${BASE_URL}/regstartexist`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify bvn
    verifyOnboardBvn: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("businessid", context.getters.getRegUser.business_id);
        formData.append("userid", context.getters.getRegUser.userid);
        formData.append("bvnn", data.bvn);
        formData.append("env", "web");
        return await fetch(`${BASE_URL}/bvverify`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //resend otp
    resendOnboardOtp: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("businessid", context.getters.getRegUser.business_id);
        formData.append("userid", context.getters.getRegUser.userid);
        formData.append("bvn", data.bvn);
        formData.append("type", data.type);
        formData.append("env", "web");
        return await fetch(`${BASE_URL}/resendregotp`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //verify bvn
    confirmOnboardBvn: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("businessid", context.getters.getRegUser.business_id);
        formData.append("userid", context.getters.getRegUser.userid);
        formData.append("otp", data.otp);
        formData.append("env", "web");
        return await fetch(`${BASE_URL}/bvotp`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //business information
    onboardingBusInfo: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("businessid", context.getters.getRegUser.business_id);
        formData.append("userid", context.getters.getRegUser.userid);
        formData.append("desc", data.desc);
        formData.append("adr", data.address);
        formData.append("lga", data.lga);
        formData.append("state", data.state);
        formData.append("city", data.city);
        formData.append("busemail", data.busemail);
        formData.append("country", data.country);
        formData.append("localcurr", data.localcurr);
        formData.append("dialcode", data.dialcode);
        formData.append("busphone", data.busphone);
        formData.append("cacreg", data.cacreg);
        formData.append("busname1", data.busname1);
        formData.append("busname2", data.busname2);
        formData.append("busname3", data.busname3);
        formData.append("prefer_bname", data.prefer_bname);
        formData.append("env", "web");
        return await fetch(`${BASE_URL}/businfo`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //doc upload
    onboardingDocUpload: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("businessid", context.getters.getRegUser.business_id);
        formData.append("userid", context.getters.getRegUser.userid);
        formData.append("env", "web");
        formData.append(data.mode, data.file);
        return await fetch(`${BASE_URL}/docupload`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //registration overview
    onboardingOverview: async (context) => {
      try {
        const formData = new FormData();
        formData.append("businessid", context.getters.getRegUser.business_id);
        formData.append("userid", context.getters.getRegUser.userid);
        formData.append("env", "web");
        return await fetch(`${BASE_URL}/reg-overview`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //onboarding conctact sale
    onboardingSaleForm: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("email", data.email);
        formData.append("fname", data.fname);
        formData.append("sname", data.sname);
        formData.append("phn", data.phone);
        formData.append("company_name", data.company_name);
        formData.append("company_adr", data.company_adr);
        formData.append("company_post", data.company_post);
        formData.append("company_desc", data.company_desc);
        formData.append("env", "web");
        return await fetch(`${BASE_URL}/contactsale`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //check invite
    checkInvite: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("busid", data.busid);
        formData.append("ivtoken", data.token);
        return await fetch(`${BASE_URL}/checkinvite`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    //accept invite
    acceptInvite: async (context, data) => {
      try {
        const formData = new FormData();
        formData.append("busid", data.busid);
        formData.append("ivtoken", data.token);
        formData.append("firstname", data.fname);
        formData.append("lastname", data.lname);
        formData.append("pww", data.password);
        formData.append("phn", data.phone);
        return await fetch(`${BASE_URL}/acceptinvite`, {
          method: "POST",
          body: formData,
        });
      } catch (e) {
        console.log(e.message);
      }
    },

    httpRequest: async (context, payload) => {
      try {
        const formData = new FormData();
        formData.append("sesscode", context.getters.getUser.sesscode);
        formData.append("userid", context.getters.getUser.userid);
        formData.append("businessid", context.getters.getUser.business_id);
        formData.append("env", "web");
        for (const key in payload.data) {
          formData.append(key, payload.data[key]);
        }

        const res = await fetch(
          payload.rawUrl ?? `${BASE_URL}/${payload.url}`,
          {
            method: "POST",
            body: formData,
            headers: payload.headers,
          }
        );
        const response = await res.json().then((resp) => {
          return resp;
        });

        //update cache
        if (response.status) {
          if (payload.caches) {
            payload.caches.forEach((cache) => {
              context.commit("updateCache", {
                key: cache.key,
                value: response[cache.value],
              });
            });
          }
        }
        return response;
      } catch (e) {
        console.log(e);
      }
    },

    //fetch user account details
    fetchDailyGraph: async (context) => {
      await context.dispatch("httpRequest", {
        url: "graphtrans",
        caches: [{ key: "dailygraph", value: "data" }],
      });
    },

    //fetch sub accounts
    fetchSubAccounts: async (context) => {
      await context.dispatch("httpRequest", {
        url: "subaccounts",
        caches: [{ key: "subaccounts", value: "data" }],
      });
    },

    //fetch payout offer
    fetchPayoutOffer: async (context) => {
      await context
        .dispatch("httpRequest", {
          url: "payout-offer",
        })
        .then((response) => {
          if (response.status) {
            context.commit("updateCache", {
              key: "payoutOffer",
              value: response,
            });
          }
        });
    },

    //fetch payout history
    fetchPayoutHistory: async (context) => {
      await context.dispatch("httpRequest", {
        url: "loanhistory",
        caches: [{ key: "loanhistory", value: "data" }],
      });
    },

    //fetch trans limit
    fetchTransLimit: async (context) => {
      await context.dispatch("httpRequest", {
        url: "translimit",
        caches: [{ key: "translimit", value: "data" }],
      });
    },

    //fetch verstatus
    fetchVerificationStatus: async (context) => {
      await context.dispatch("httpRequest", {
        url: "verstatus",
        caches: [{ key: "verstatus", value: "data" }],
      });
    },

    //fetch events
    fetchEvents: async (context) => {
      await context.dispatch("httpRequest", {
        url: "eventlist",
        caches: [{ key: "myevents", value: "eventlist" }],
      });
    },

    //fetch event ticket
    fetchEventTicket: async (context) => {
      await context.dispatch("httpRequest", {
        url: "tickettype",
        data: { eventid: context.getters.getCache.selectedEvent?.eventid },
        caches: [{ key: "eventtickets", value: "ticketlist" }],
      });
    },

    //fetch disputes
    fetchDisputes: async (context) => {
      await context.dispatch("httpRequest", {
        url: "disputes",
        caches: [{ key: "disputes", value: "data" }],
      });
    },

    //fetch cashier trans
    fetchCashierMfiTrans: async (context, data) => {
      await context
        .dispatch("httpRequest", {
          url: "mficashiertrans",
          data: {
            cashierid: context.getters.getUser.userid,
            filterby: data.filterby,
            custom: data.custom ?? 0,
          },
          caches: [
            { key: "mficashiertrans", value: "data" },
            { key: "mficashierbal", value: "wbal" },
          ],
        })
        .then((response) => {
          if (response.status) {
            context.commit("updateCache", {
              key: "mficashiertrans",
              value: response,
            });
          }
        });
    },
  },
};

export default user;
